import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Container } from "@chakra-ui/react";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Changelog | THE PROJECT STATUS" urlPage="/changelog" mdxType="SEO" />
    <Container maxW="800px" minH="640px" px={4} mdxType="Container">
      <h1 {...{
        "id": "changelog",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#changelog",
          "aria-label": "changelog permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Changelog`}</h1>
      <p>{`The webapp THE PROJECT STATUS adheres to
`}<a parentName="p" {...{
          "href": "https://keepachangelog.com/en/1.0.0/",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer"
        }}>{`keep a changelog`}</a>{`.`}</p>
      <h2 {...{
        "id": "known-errors",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#known-errors",
          "aria-label": "known errors permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Known Errors`}</h2>
      <p>{`none`}</p>
      <h2 {...{
        "id": "0176alpha-2021-11-29",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0176alpha-2021-11-29",
          "aria-label": "0176alpha 2021 11 29 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.17.6.alpha]`}{` 2021-11-29`}</h2>
      <h3 {...{
        "id": "website",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website",
          "aria-label": "website permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website`}</h3>
      <ul>
        <li parentName="ul">{`Blog → Concepts`}</li>
        <li parentName="ul">{`Remove concept dates`}</li>
        <li parentName="ul">{`Eagerly load images above the fold in concepts`}</li>
        <li parentName="ul">{`Add a new concept on KANBAN`}</li>
      </ul>
      <h2 {...{
        "id": "0175alpha-2021-10-05",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0175alpha-2021-10-05",
          "aria-label": "0175alpha 2021 10 05 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.17.5.alpha]`}{` 2021-10-05`}</h2>
      <h3 {...{
        "id": "app",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app",
          "aria-label": "app permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Fix that aggregated nodes also consider precs`}</li>
      </ul>
      <h2 {...{
        "id": "0174alpha-2021-09-29",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0174alpha-2021-09-29",
          "aria-label": "0174alpha 2021 09 29 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.17.4.alpha]`}{` 2021-09-29`}</h2>
      <h3 {...{
        "id": "app-1",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-1",
          "aria-label": "app 1 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Fix crash when folding in ROOT node`}</li>
        <li parentName="ul">{`Fix regex for person names (now also allows dashes). Improve error message.`}</li>
        <li parentName="ul">{`Fix faulty select behavior in SelectPerson component`}</li>
      </ul>
      <h4>{`Improvements`}</h4>
      <ul>
        <li parentName="ul">{`Replace minus with times for deleting a person`}</li>
        <li parentName="ul">{`Added MoveBox also to bottom of Status Sheet`}</li>
      </ul>
      <h2 {...{
        "id": "0173alpha-2021-09-05",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0173alpha-2021-09-05",
          "aria-label": "0173alpha 2021 09 05 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.17.3.alpha]`}{` 2021-09-05`}</h2>
      <h3 {...{
        "id": "app-2",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-2",
          "aria-label": "app 2 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`Improvements`}</h4>
      <ul>
        <li parentName="ul">{`Auto save WHO and TO WHOM onBlur`}</li>
      </ul>
      <h2 {...{
        "id": "0172alpha-2021-08-05",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0172alpha-2021-08-05",
          "aria-label": "0172alpha 2021 08 05 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.17.2.alpha]`}{` 2021-08-05`}</h2>
      <h3 {...{
        "id": "app-3",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-3",
          "aria-label": "app 3 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Span was showing wrong span.`}</li>
        <li parentName="ul">{`Fixed bugs in connection with tree down up`}</li>
        <li parentName="ul">{`Reversed: “By when cannot be before from when. The minimum span is zero.” BY
WHEN can be before FROM WHEN and the minimum span can now be negative again.`}</li>
      </ul>
      <h2 {...{
        "id": "0171alpha-2021-08-05",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0171alpha-2021-08-05",
          "aria-label": "0171alpha 2021 08 05 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.17.1.alpha]`}{` 2021-08-05`}</h2>
      <h3 {...{
        "id": "app-4",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-4",
          "aria-label": "app 4 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`Improvements`}</h4>
      <ul>
        <li parentName="ul">{`Auto save of changes to Persons`}</li>
        <li parentName="ul">{`Auto save of changes to Setup`}</li>
        <li parentName="ul">{`Only show ’+’ after last person if this person has a name`}</li>
        <li parentName="ul">{`Shift mouse click now sets traffic light status backwards`}</li>
        <li parentName="ul">{`As a warning sign the position and span are colored in dark pink if the span
is 0 or negative.`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Role was lost in case of updates of a person`}</li>
        <li parentName="ul">{`Fixed bugs in connection with pins (= milestones)`}</li>
        <li parentName="ul">{`Corrected position of tooltip for PRECS`}</li>
        <li parentName="ul">{`By when cannot be before from when. The minimum span is zero.`}</li>
        <li parentName="ul">{`Failed to add 3rd status date and beyond.`}</li>
      </ul>
      <h2 {...{
        "id": "0170alpha-2021-07-27",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0170alpha-2021-07-27",
          "aria-label": "0170alpha 2021 07 27 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.17.0.alpha]`}{` 2021-07-27`}</h2>
      <h3 {...{
        "id": "app-5",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-5",
          "aria-label": "app 5 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Progress tab: progress analysis with charts.`}</li>
        <li parentName="ul">{`Deliverables with negative slack turn red also in sheet, not only in
Gantt-Chart.`}</li>
      </ul>
      <h4>{`Improvements`}</h4>
      <ul>
        <li parentName="ul">{`The Start Date, the currency symbol, and the color borders can only be changed
or dated back at initial setup and not at any further status date.`}</li>
        <li parentName="ul">{`Delay of 500ms before error message shows.`}</li>
        <li parentName="ul">{`DEGREE OF COMPLETION = Σ SPENTs|DOC>0 / Σ PROJECTIONs. All projections are
aggregated up, but in order to not count ‘resultless’ amounts of time, SPENT
is only aggregated up if the Degree Of Completion % > 0.`}</li>
        <li parentName="ul">{`Better explanations in some tooltips.`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Borders for Customer Satisfaction had wrong label.`}</li>
        <li parentName="ul">{`Forms were not reset after status travels.`}</li>
        <li parentName="ul">{`Submission of BY WHEN with ’+” and hour information led to an erroneous input
format.`}</li>
      </ul>
      <h3 {...{
        "id": "website-1",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-1",
          "aria-label": "website 1 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website`}</h3>
      <h4>{`Improvements`}</h4>
      <ul>
        <li parentName="ul">{`Added section for ‘Progress’`}</li>
        <li parentName="ul">{`Some improved wordings and clarifications`}</li>
        <li parentName="ul">{`Updated some screenshots`}</li>
        <li parentName="ul">{`Added robots.txt`}</li>
      </ul>
      <h2 {...{
        "id": "0166alpha-2021-06-19",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0166alpha-2021-06-19",
          "aria-label": "0166alpha 2021 06 19 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.16.6.alpha]`}{` 2021-06-19`}</h2>
      <h3 {...{
        "id": "app-6",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-6",
          "aria-label": "app 6 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`a dependency bug caused missing dependents`}</li>
      </ul>
      <h4>{`Improvements`}</h4>
      <ul>
        <li parentName="ul">{`better setup of Unresolved Issues`}</li>
        <li parentName="ul">{`additional Tooltips`}</li>
      </ul>
      <h3 {...{
        "id": "website-2",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-2",
          "aria-label": "website 2 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website`}</h3>
      <ul>
        <li parentName="ul">{`Update to gatsby-plugin-images`}</li>
      </ul>
      <h2 {...{
        "id": "0165alpha-2021-05-26",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0165alpha-2021-05-26",
          "aria-label": "0165alpha 2021 05 26 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.16.5.alpha]`}{` 2021-05-26`}</h2>
      <h3 {...{
        "id": "app-7",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-7",
          "aria-label": "app 7 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`When moving up or down there is a risk of potentially generating a circular
dependencies. This causes artefacts. In case this happens a warning is issued.`}</li>
      </ul>
      <h2 {...{
        "id": "0164alpha-2021-05-19",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0164alpha-2021-05-19",
          "aria-label": "0164alpha 2021 05 19 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.16.4.alpha]`}{` 2021-05-19`}</h2>
      <h3 {...{
        "id": "app-8",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-8",
          "aria-label": "app 8 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`Hot Fixes`}</h4>
      <ul>
        <li parentName="ul">{`SPENT did not accept input, when files were used from a previous API`}</li>
      </ul>
      <h2 {...{
        "id": "0163alpha-2021-05-18",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0163alpha-2021-05-18",
          "aria-label": "0163alpha 2021 05 18 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.16.3.alpha]`}{` 2021-05-18`}</h2>
      <h3 {...{
        "id": "app-9",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-9",
          "aria-label": "app 9 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Correct move behavior. Allow move across different parents.`}</li>
      </ul>
      <h2 {...{
        "id": "0162alpha-2021-05-16",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0162alpha-2021-05-16",
          "aria-label": "0162alpha 2021 05 16 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.16.2.alpha]`}{` 2021-05-16`}</h2>
      <h3 {...{
        "id": "app-10",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-10",
          "aria-label": "app 10 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Aggregated degree was > 100%`}</li>
        <li parentName="ul">{`Reset Sheet was did not work properly`}</li>
      </ul>
      <h2 {...{
        "id": "0161alpha-2021-05-10",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0161alpha-2021-05-10",
          "aria-label": "0161alpha 2021 05 10 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.16.1.alpha]`}{` 2021-05-10`}</h2>
      <h3 {...{
        "id": "app-11",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-11",
          "aria-label": "app 11 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`Hot Fixes`}</h4>
      <ul>
        <li parentName="ul">{`MoveIn button did not aggregate node properly. This led to subsequent errors
in the position generation.`}</li>
      </ul>
      <h2 {...{
        "id": "0160alpha-2021-05-09",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0160alpha-2021-05-09",
          "aria-label": "0160alpha 2021 05 09 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.16.0.alpha]`}{` 2021-05-09`}</h2>
      <h3 {...{
        "id": "app-12",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-12",
          "aria-label": "app 12 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Additional option for inputing BY WHEN dates. ’+” followed by a duration
string, eg. +1w3d4`}</li>
        <li parentName="ul">{`Add KANBAN board move behavior`}</li>
        <li parentName="ul">{`Allows nodes to become aggregates and allow empty aggregates`}</li>
        <li parentName="ul">{`Add Work In Progress (WIP) and WIP LIMITS, breached WIP LIMITS turn reddish`}</li>
        <li parentName="ul">{`Show hourglass, wenn SPENT >= 1 and DEGREE < 100%`}</li>
        <li parentName="ul">{`Show node ID for cross-reference and Kanban interpretation`}</li>
        <li parentName="ul">{`New button for adding a node as a child of a node`}</li>
        <li parentName="ul">{`New button for toggling a node between is aggregate true or false. This only
works if the level of the node is >= 2 and the node is empty in all
aggregatable features.`}</li>
        <li parentName="ul">{`Provide alphabetical sorting for WHO and TO WHOM`}</li>
        <li parentName="ul">{`Add project ID to identify project in a multi project management environment.`}</li>
      </ul>
      <h3 {...{
        "id": "improvements",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#improvements",
          "aria-label": "improvements permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Improvements`}</h3>
      <ul>
        <li parentName="ul">{`Improve headlines, WHO + (OWNER), DELIVERS WHAT + (ITEM OR SET)`}</li>
        <li parentName="ul">{`Improve some tooltips.`}</li>
        <li parentName="ul">{`Improved checking of circular references`}</li>
      </ul>
      <h4>{`Changed Features`}</h4>
      <ul>
        <li parentName="ul">{`Nodes on level 1 are only allowed to be aggregated nodes`}</li>
        <li parentName="ul">{`Newly created nodes will have end of business day as BY WHEN`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Precedents did not always show up on main line, but were hiding in details`}</li>
        <li parentName="ul">{`Deleting of precedents did not always work`}</li>
      </ul>
      <h3 {...{
        "id": "website-3",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-3",
          "aria-label": "website 3 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Improved Explanations`}</li>
      </ul>
      <h2 {...{
        "id": "0154alpha-2021-05-07",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0154alpha-2021-05-07",
          "aria-label": "0154alpha 2021 05 07 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.15.4.alpha]`}{` 2021-05-07`}</h2>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Correct two bugs in connection with assigning roles from teams`}</li>
        <li parentName="ul">{`Hours in byWhen did not work properly`}</li>
      </ul>
      <h2 {...{
        "id": "0153-alpha-2021-05-05",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0153-alpha-2021-05-05",
          "aria-label": "0153 alpha 2021 05 05 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.15.3-alpha]`}{` 2021-05-05`}</h2>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Correct padding for position strings`}</li>
        <li parentName="ul">{`Correct circular reference.`}</li>
        <li parentName="ul">{`Person refs in delegates were not removed, when person was deleted.`}</li>
      </ul>
      <h2 {...{
        "id": "0152-alpha-2021-04-25",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0152-alpha-2021-04-25",
          "aria-label": "0152 alpha 2021 04 25 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.15.2-alpha]`}{` 2021-04-25`}</h2>
      <h3 {...{
        "id": "app-13",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-13",
          "aria-label": "app 13 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Set active also through Gantt-Chart`}</li>
      </ul>
      <h4>{`Hotfix`}</h4>
      <ul>
        <li parentName="ul">{`Format Caret sizes and Boxes in GanttBar properly so that Safari shows is
perfectly aligned.`}</li>
      </ul>
      <h2 {...{
        "id": "0151-alpha-2021-04-24",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0151-alpha-2021-04-24",
          "aria-label": "0151 alpha 2021 04 24 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.15.1-alpha]`}{` 2021-04-24`}</h2>
      <h3 {...{
        "id": "app-14",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-14",
          "aria-label": "app 14 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Did not handle byWhen dates properly.`}</li>
      </ul>
      <h2 {...{
        "id": "0150-alpha-2021-04-21",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0150-alpha-2021-04-21",
          "aria-label": "0150 alpha 2021 04 21 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.15.0-alpha]`}{` 2021-04-21`}</h2>
      <h3 {...{
        "id": "app-15",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-15",
          "aria-label": "app 15 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Warning for making ancestors, descendants or non-existing positions precedents`}</li>
        <li parentName="ul">{`Make project title accordion button to save one line`}</li>
        <li parentName="ul">{`Remove OK buttons by introducing auto-save`}</li>
        <li parentName="ul">{`Bring Move Buttons into their own button field to save space and remove
redundancies`}</li>
        <li parentName="ul">{`Integrated UNRESOLVED and IGNORED button into a joint button`}</li>
        <li parentName="ul">{`More tooltips`}</li>
        <li parentName="ul">{`Some design improvements`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Quality did not appear when deliverable is set to 100 for the first time`}</li>
        <li parentName="ul">{`Precedents did not allow for more than one precedent (because of REGEX)`}</li>
        <li parentName="ul">{`Correctly formatted page titles`}</li>
        <li parentName="ul">{`Dependents were not correctly deleted when precedents were removed`}</li>
        <li parentName="ul">{`Projection was only aggregated when degree > 0.`}</li>
      </ul>
      <h2 {...{
        "id": "0142-alpha-2021-04-15",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0142-alpha-2021-04-15",
          "aria-label": "0142 alpha 2021 04 15 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.14.2-alpha]`}{` 2021-04-15`}</h2>
      <h3 {...{
        "id": "app-16",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-16",
          "aria-label": "app 16 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`the aggregation algorithms did not treat isUnresolved and isIgnored properly`}</li>
        <li parentName="ul">{`the aggregation of the degree now ignores deliverables that have degree === 0`}</li>
      </ul>
      <h2 {...{
        "id": "0141-alpha-2021-04-14",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0141-alpha-2021-04-14",
          "aria-label": "0141 alpha 2021 04 14 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.14.1-alpha]`}{` 2021-04-14`}</h2>
      <h3 {...{
        "id": "app-17",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-17",
          "aria-label": "app 17 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`degree was not always an int, that lead to odd aggregations`}</li>
        <li parentName="ul">{`recursive call of nodesTreeDownUp did not hand over all params`}</li>
        <li parentName="ul">{`empty Box instead of Carets in Gantt did not have a minW and shrunk`}</li>
      </ul>
      <h2 {...{
        "id": "0140-alpha-2021-04-13",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0140-alpha-2021-04-13",
          "aria-label": "0140 alpha 2021 04 13 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.14.0-alpha]`}{` 2021-04-13`}</h2>
      <h3 {...{
        "id": "app-18",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-18",
          "aria-label": "app 18 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Score`}</li>
        <li parentName="ul">{`Top Management TODOs → Senior Management Requests`}</li>
        <li parentName="ul">{`Responsible → Delegate`}</li>
        <li parentName="ul">{`Carets for Gantt-Chart`}</li>
        <li parentName="ul">{`Initials in Gantt-Chart`}</li>
        <li parentName="ul">{`Roles are synced across tabs`}</li>
        <li parentName="ul">{`Project Manager → Project Manager/Owner`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <h3 {...{
        "id": "website-4",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-4",
          "aria-label": "website 4 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website:`}</h3>
      <ul>
        <li parentName="ul">{`description of features of v0.13.0-alpha`}</li>
        <li parentName="ul">{`Blog Project Categories`}</li>
        <li parentName="ul">{`Blog Roles & Responsibilities`}</li>
        <li parentName="ul">{`Blog Best Practices Framework`}</li>
      </ul>
      <h2 {...{
        "id": "0130-alpha-2021-03-23",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0130-alpha-2021-03-23",
          "aria-label": "0130 alpha 2021 03 23 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.13.0-alpha]`}{` 2021-03-23`}</h2>
      <h3 {...{
        "id": "app-19",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-19",
          "aria-label": "app 19 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Added Top Management TODOs`}</li>
        <li parentName="ul">{`Added Change Mgt, Quality Mgt, Risk Mgt and Knowledge Mgt.`}</li>
        <li parentName="ul">{`Exam function`}</li>
        <li parentName="ul">{`Double underline Positions`}</li>
        <li parentName="ul">{`Improved tooltips`}</li>
        <li parentName="ul">{`Simpler and faster project reducer`}</li>
        <li parentName="ul">{`Cash → expenses`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`byWhen aggregation in edge case`}</li>
      </ul>
      <h3 {...{
        "id": "website-5",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-5",
          "aria-label": "website 5 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website:`}</h3>
      <ul>
        <li parentName="ul">{`description of features of v0.13.0-alpha`}</li>
      </ul>
      <h4>{`Bugs`}</h4>
      <h2 {...{
        "id": "0121-alpha-2021-03-08",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0121-alpha-2021-03-08",
          "aria-label": "0121 alpha 2021 03 08 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.12.1-alpha]`}{` 2021-03-08`}</h2>
      <h3 {...{
        "id": "app-20",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-20",
          "aria-label": "app 20 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`New Status Date can now be only one day after previous one`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`revert: consider isIgnored when calculating fromWhen`}</li>
        <li parentName="ul">{`negative span, projection and slack were wrongly calculated`}</li>
        <li parentName="ul">{`Aggregation of Degree % only when at least one child has a degree > 0`}</li>
        <li parentName="ul">{`Set quality back to 0 if degree gets < 100`}</li>
      </ul>
      <h3 {...{
        "id": "website-6",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-6",
          "aria-label": "website 6 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website:`}</h3>
      <h4>{`Bugs`}</h4>
      <ul>
        <li parentName="ul">{`color links in mdx`}</li>
      </ul>
      <h2 {...{
        "id": "0120-alpha-2021-03-04",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0120-alpha-2021-03-04",
          "aria-label": "0120 alpha 2021 03 04 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.12.0-alpha]`}{` 2021-03-04`}</h2>
      <h3 {...{
        "id": "app-21",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-21",
          "aria-label": "app 21 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Week view for Gantt-Chart`}</li>
        <li parentName="ul">{`Paperbin for latest Status Date`}</li>
        <li parentName="ul">{`Different Colors of Status Dates (blue) and Current Status Dates (pink)`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Styling of Select Boxes when locked is not greyed out`}</li>
        <li parentName="ul">{`Correct display deadline, forecast & accumulation of those`}</li>
        <li parentName="ul">{`Properly align people in summary sheet`}</li>
        <li parentName="ul">{`consider isIgnored when calculating fromWhen`}</li>
      </ul>
      <h2 {...{
        "id": "0110-alpha-2021-02-26",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0110-alpha-2021-02-26",
          "aria-label": "0110 alpha 2021 02 26 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.11.0-alpha]`}{` 2021-02-26`}</h2>
      <h3 {...{
        "id": "app-22",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-22",
          "aria-label": "app 22 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`MoveIn uses top neighbor as aggregation, when it is empty, i.e. has not
received user information.`}</li>
        <li parentName="ul">{`Hidden debug switch`}</li>
        <li parentName="ul">{`Clean tree from dependencies`}</li>
        <li parentName="ul">{`Prevent circular references`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <h2 {...{
        "id": "0100-alpha-2021-02-25",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#0100-alpha-2021-02-25",
          "aria-label": "0100 alpha 2021 02 25 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.10.0-alpha]`}{` 2021-02-25`}</h2>
      <h3 {...{
        "id": "website-7",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-7",
          "aria-label": "website 7 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Infos with images`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`SEO properties`}</li>
        <li parentName="ul">{`dateFormatted in PageFooter`}</li>
      </ul>
      <h3 {...{
        "id": "app-23",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-23",
          "aria-label": "app 23 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Empty nodes have now new moveIn and moveOut behavior.`}</li>
        <li parentName="ul">{`Aggregation node takes over information from children and adds “Total”.`}</li>
        <li parentName="ul">{`New ‘Reset Sheet’ button.`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Fixed bug in moveIn behavior.`}</li>
      </ul>
      <h2 {...{
        "id": "090-alpha-2021-02-22",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#090-alpha-2021-02-22",
          "aria-label": "090 alpha 2021 02 22 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.9.0-alpha]`}{` 2021-02-22`}</h2>
      <h3 {...{
        "id": "website-8",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-8",
          "aria-label": "website 8 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website`}</h3>
      <ul>
        <li parentName="ul">{`Add thanks`}</li>
      </ul>
      <h3 {...{
        "id": "app-24",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-24",
          "aria-label": "app 24 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Add confirmation for Reset`}</li>
        <li parentName="ul">{`Add confirmation of successfully changing the status date`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Remove crash, when entered byWhen, spent or degree was ""`}</li>
        <li parentName="ul">{`Remove crash, when byWhen date was before project Date Start`}</li>
        <li parentName="ul">{`Correct FC-value and aggregation`}</li>
        <li parentName="ul">{`Fix form reset after reset`}</li>
      </ul>
      <h2 {...{
        "id": "082-alpha-2021-02-22",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#082-alpha-2021-02-22",
          "aria-label": "082 alpha 2021 02 22 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.8.2-alpha]`}{` 2021-02-22`}</h2>
      <h3 {...{
        "id": "website-9",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-9",
          "aria-label": "website 9 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website:`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Correct SEO`}</li>
        <li parentName="ul">{`Correct link to Factory Reset`}</li>
      </ul>
      <h3 {...{
        "id": "app-25",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-25",
          "aria-label": "app 25 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Correct SEO and add Open Graph`}</li>
        <li parentName="ul">{`Remove unknowns from ParamsUpload`}</li>
      </ul>
      <h2 {...{
        "id": "081-alpha-2021-02-21",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#081-alpha-2021-02-21",
          "aria-label": "081 alpha 2021 02 21 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.8.1-alpha]`}{` 2021-02-21`}</h2>
      <h3 {...{
        "id": "website-10",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-10",
          "aria-label": "website 10 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website:`}</h3>
      <h4>{`New Features`}</h4>
      <ul>
        <li parentName="ul">{`Text in Info and Blog`}</li>
      </ul>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`react-helmet (WIP)`}</li>
      </ul>
      <h3 {...{
        "id": "app-26",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-26",
          "aria-label": "app 26 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <h4>{`Bug Fixes`}</h4>
      <ul>
        <li parentName="ul">{`Upload`}</li>
        <li parentName="ul">{`Better viewport of row inputs`}</li>
      </ul>
      <h2 {...{
        "id": "080-alpha-2021-01-31",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h2" {...{
          "href": "#080-alpha-2021-01-31",
          "aria-label": "080 alpha 2021 01 31 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`[0.8.0-alpha]`}{` 2021-01-31`}</h2>
      <h3 {...{
        "id": "website-11",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#website-11",
          "aria-label": "website 11 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Website:`}</h3>
      <ul>
        <li parentName="ul">{`Initial public version`}</li>
      </ul>
      <h3 {...{
        "id": "app-27",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#app-27",
          "aria-label": "app 27 permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`App:`}</h3>
      <ul>
        <li parentName="ul">{`Initial public version`}</li>
      </ul>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      